const initBlock = async () => {
  const sections = Array.from(document.getElementsByClassName('b-testimonials'));

  for (const section of sections) {
    const sliderPreviewEl = section.querySelector('.swiper.swiper-preview');
    const sliderTestimonialsEl = section.querySelector('.swiper.swiper-testimonials');

    if (!sliderPreviewEl || !sliderTestimonialsEl) {
      continue;
    }

    const {Swiper} = await import('swiper');
    const {Navigation, EffectFade, Controller} = await import('swiper/modules');

    const sliderPreview = new Swiper(sliderPreviewEl, {
      modules: [EffectFade],
      slidesPerView: 1,
      spaceBetween: 16,
      effect: 'fade',
      draggable: false,
      clickable: false,
      slideToClickedSlide: false,
      preventClicks: true,
      preventClicksPropagation: true,
      allowTouchMove: false,
    });

    const sliderTestimonials = new Swiper(sliderTestimonialsEl, {
      modules: [Navigation, Controller],
      slidesPerView: 1,
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    sliderTestimonials.controller.control = sliderPreview;
  }
};

window.requestAnimationFrame(async function check() {
  document.body ? await initBlock() : window.requestAnimationFrame(check);
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
